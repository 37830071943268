import React from "react";
import {Confirm, Modal, Tab} from "semantic-ui-react";
import {Request} from "../../../utils/Request";
import Utils, {Loading, MessageProps, my_initial_confirm, MyConfirmAction, MyMessage, ShowPDF} from "../../../utils/Utils";
import {payment_types} from "../payments/AddPayment";
import DiagnosisPayment from "./DiagnosisPayment";
import DiagnosisTriage from "./DiagnosisTriage";
import JSPrinter from "../../../utils/JSPrinter";
import AddPictures from "./AddPictures";

export interface Triage {
    date: string
    state: string[]
    signs: string[]
}

export const initial_triage: Triage = {date: "", signs: [], state: []}

export type test_types = 'ordinary' | 'express' | 'igg_igm' | 'igg_igm_pcr'

export interface Patient {
    client_id: number
    email_address: string
    mobile_contact: string
    client_name: string
    group_id: number
    group_name: string
    discount: number
    discount_reason: string
    payment_amount: number
    test_type: test_types,
    payment_tiers: Array<{ test_type: test_types, test_amount: number, test_name: string }>
    company: boolean
    tier_amount: number
    amount_payable: number,
    payment_method: 'cash' | 'credit',
    group_payment: { express: number, ordinary: number, igg_igm: number, igg_igm_pcr: number }
    other_payments: { amount: string | number, description: string, has: boolean }
    virology: 1 | 0
    purpose: string
    virology_amount: number
    payments: Array<{ payment_type: payment_types, ref_no: string, account_name: string, amount_paid: number }>
    travel_time: string
    before_midnight: boolean
    tested_positive: 'Yes' | 'No'
    vaccinated: 'Yes' | 'No'
    vaccine: '' | 'AstraZeneca' | 'Pfizer' | 'Moderna' | 'Sinopham' | 'Johnson & Johnson' | 'Sinovac'
    vaccine_doses: number
    last_dose: string
    eac_pass_id: string
}

export const initial_patient: Patient = {
    eac_pass_id: "", last_dose: "", vaccine_doses: 0, tested_positive: 'No', vaccinated: 'No', vaccine: '',
    purpose: "Traveller", virology_amount: 0, other_payments: {amount: '0', description: '', has: false}, before_midnight: false,
    group_payment: {express: 0, ordinary: 0, igg_igm: 0, igg_igm_pcr: 0}, payment_tiers: [], group_name: "", discount_reason: '',
    discount: 0, group_id: 0, payment_amount: 0, payment_method: 'cash', test_type: 'ordinary', client_id: 0, client_name: "",
    email_address: "", mobile_contact: "", company: false, amount_payable: 0, tier_amount: 0, virology: 0, payments: [], travel_time: ''
}

export default function CreateTest(params: { client_id: number, close: () => void, user_token: string, show: boolean }) {
    const [triage, setTriage] = React.useState(initial_triage)
    const [loader, setLoader] = React.useState({show: false, message: ""})

    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [saving, setSaving] = React.useState(false)
    const [patient, setPatient] = React.useState<Patient>(initial_patient)

    const [showReceipt, setShowReceipt] = React.useState({show: false, url: ""})

    const create_test = (client_id: number) => {
        setLoader({message: "Loading client data, please wait", show: true})

        Request.initialise_diagnosis({client_id: client_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setPatient(response.data.data)
                } else {
                    setMessage({active: true, type: 'error', message: "Could not load client data, please retry"})
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                setMessage({active: true, type: 'error', message: "Could not load client data, please retry"})
            })
    }

    const [addPics, setAddPic] = React.useState({show: false, diagnosis_no: "", diagnosis_id: 0})

    const save_diagnosis = (proceed: 1 | 0) => {
        const create_test = () => {
            setConfirm({...confirm, open: false})
            setSaving(true)
            Request.save_test({
                payments: JSON.stringify(patient.payments),
                amount_paid: patient.amount_payable,
                client_id: patient.client_id,
                triage: JSON.stringify(triage),
                test_type: patient.test_type,
                group_id: patient.company ? patient.group_id : 0,
                discount: patient.discount,
                payment_method: patient.payment_method,
                discount_reason: patient.discount_reason,
                other_payment: patient.other_payments.has ? parseFloat(patient.other_payments.amount.toString()) : 0,
                other_payments: patient.other_payments.has ? patient.other_payments.description : "",
                proceed: proceed,
                virology: patient.virology,
                purpose: patient.purpose,
                travel_time: (patient.purpose === 'Traveller' && patient.test_type === 'express' && patient.before_midnight) ? patient.travel_time : '',
                tested_positive: patient.tested_positive,
                vaccinated: patient.vaccinated,
                vaccine: patient.vaccine,
                last_dose: patient.last_dose,
                eac_pass_id: patient.eac_pass_id,
                vaccine_doses: patient.vaccine_doses
            })
                .then((response) => {
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            JSPrinter.print_code(response.data.data.diagnosis_no)

                            setLoader({message: "Generating payment receipt, please wait", show: true})
                            Request.print_receipt({diagnosis_id: response.data.data.diagnosis_id, payment_info_id: 0})
                                .then((response) => {
                                    setLoader({message: "Generating receipt, please wait", show: false})
                                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                                        if (Request.COMPANY === "PHARMA") {
                                            JSPrinter.print_receipt(response.data.data)
                                        } else {
                                            setShowReceipt({show: true, url: Request.BASE_URL + response.data.data.url})
                                        }
                                    }
                                })
                                .catch(() => {
                                    setLoader({message: "Printing receipt, please wait", show: false})
                                })

                            params.close()
                            setAddPic({
                                diagnosis_id: response.data.data.diagnosis_id,
                                diagnosis_no: response.data.data.diagnosis_no,
                                show: true
                            })
                        } else if (response.data.code === 3) {
                            setConfirm({
                                cancelButton: "Cancel", confirmButton: "Proceed",
                                content: "Client made a test (" + response.data.data.lapse + " ago). Do you want to proceed making a new test",
                                onCancel: () => setConfirm({...confirm, 'open': false}),
                                onConfirm: () => save_diagnosis(1),
                                open: true
                            })
                        } else {
                            setMessage({active: true, message: "Error while saving test, please consult the admin", type: 'error'})
                        }
                    } else {
                        setMessage({active: true, message: "Error while saving test, please retry", type: 'error'})
                    }
                    setSaving(false)
                })
                .catch(() => {
                    setSaving(false)
                    setMessage({active: true, message: "Error while saving test, please retry", type: 'error'})
                })
        }

        if (proceed === 1) {
            create_test()
        } else {
            if (patient.client_id === 0) {
                setMessage({active: true, message: "Please first select a client", type: 'error'})
            } else if (patient.purpose === '') {
                setMessage({active: true, message: "Select purpose of making test", type: 'error'})
            } else if (patient.vaccinated === 'Yes' && patient.vaccine === "") {
                setMessage({active: true, message: "Select a vaccine name", type: 'error'})
            } else if (patient.before_midnight && patient.travel_time === '') {
                setMessage({active: true, message: "Enter the travel time for client", type: 'error'})
            } else if (patient.other_payments.has && patient.other_payments.description.trim().length < 5) {
                setMessage({active: true, message: "Please describe the purpose of extra income earned", type: 'error'})
            } else if (patient.other_payments.has &&
                (!Utils.is_valid_number(patient.other_payments.amount) || parseFloat(patient.other_payments.amount.toString()) < 0)) {
                setMessage({active: true, message: "Please enter valid other payments gotten amount", type: 'error'})
            } else {
                let total_collected = 0
                if (patient.payment_method === 'cash') {
                    patient.payments.forEach((payment) => {
                        total_collected += payment.amount_paid
                    })
                    let total_payable = patient.amount_payable +
                        (patient.other_payments.has ? parseFloat(patient.other_payments.amount.toString()) : 0)
                    if (total_payable !== total_collected) {
                        setMessage({
                            active: true, type: 'error', message: 'Payments do not match, '
                                + Utils.comma_number(total_collected) + ' has been recorded'
                        })
                        return
                    }
                }
                setConfirm({
                    ...confirm, open: true, content: 'Do you want to continue saving test data??',
                    onConfirm: () => create_test(),
                    onCancel: () => setConfirm({...confirm, 'open': false})
                })
            }
        }
    }

    React.useEffect(() => {
        if (params.show && params.client_id > 0) {
            create_test(params.client_id)
            setTriage(initial_triage)
        }
    }, [params.show])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <AddPictures show={addPics.show} diagnosis_id={addPics.diagnosis_id} diagnosis_no={addPics.diagnosis_no}
                         close={() => setAddPic({diagnosis_id: 0, diagnosis_no: "", show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} size='mini' content={confirm.content}/>

            <Modal size='fullscreen' open={params.show} centered={false}
                   closeOnDimmerClick={false} closeOnDocumentClick={false} closeOnEscape={false}>
                <div className="create_test_modal">
                    <div className='diagnosis_data_container'>
                        <Tab menu={{pointing: true}}
                             panes={[
                                 {
                                     menuItem: 'Diagnosis Triage',
                                     render: () => <Tab.Pane attached={false}>
                                         <DiagnosisTriage triage={triage} setTriage={setTriage}/>
                                     </Tab.Pane>,
                                 },
                                 {
                                     menuItem: 'Diagnosis Payment',
                                     render: () => <Tab.Pane attached={false}>
                                         <DiagnosisPayment
                                             patient={patient} close={params.close} user_token={params.user_token}
                                             save_diagnosis={save_diagnosis} saving={saving} setPatient={setPatient}/>
                                     </Tab.Pane>,
                                 },
                             ]}
                        />
                    </div>
                </div>
            </Modal>

            {/*printing modal*/}
            <ShowPDF show={showReceipt.show} close={() => setShowReceipt({...showReceipt, show: false})} url={showReceipt.url}/>
        </>
    )
}
