import {Modal} from "semantic-ui-react";
import {Loading} from "../../../../../utils/Utils";
import React from "react";
import {Request} from "../../../../../utils/Request";
import {ReportTest} from "../PCRTests";

interface User {

}

export default function DiagnosisDetails(params: {
    tests: ReportTest[], setTests: (tests: ReportTest[]) => void, diagnosis_id: number, close: () => void
}) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [user, setUser] = React.useState<User>({})

    const get_test_data = (diagnosis_id: number) => {
        setLoader({message: "Loading test data, please wait", show: true})
        Request.get_test_data({diagnosis_id: diagnosis_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setUser(response.data.data)
                } else {
                    params.close()
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                params.close()
            })
    }

    React.useEffect(() => {
        if (params.diagnosis_id > 0) {
            get_test_data(params.diagnosis_id)
        }
    }, [params.diagnosis_id])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Modal size='mini' open={params.diagnosis_id > 0} centered={false} onClose={params.close}>
                <div className="verify_container">
                    <div className="label">Diagnosis Results</div>
                    <div className="row m-0">

                    </div>

                    <div className="label mt-3">Clinical Diagnosis Results</div>

                    <div className="label mt-3">Other Conditions</div>
                </div>
            </Modal>
        </>
    )
}
