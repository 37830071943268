import React from "react";
import {Request} from "../../../../../../utils/Request";
import Utils, {Loading, MessageProps, MyInput, MyMessage, MySelect} from "../../../../../../utils/Utils";
import {Genes, ReportTest} from "../../PCRTests";
import {Button, Modal, Table} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";
import {GeneValues, Interpretation, Machine} from "./PCRMachines";

export default function UpdateResults(params: {
    diagnosis_no: string, setTests: (tests: ReportTest[]) => void, close: () => void, machines: Machine[], machine_id: number,
    tests: ReportTest[], results: "Positive" | 'Negative' | 'N/A' | 'Invalid' | 'Inconclusive', genes: Genes[]
}) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [result, setResult] = React.useState({results: params.results, genes: params.genes})
    const [values, setValues] = React.useState<GeneValues>({})

    const close = () => {
        setResult({results: 'N/A', genes: []})
        setValues({})
        params.close()
    }

    const update_results = () => {
        let has_error = false
        for (let index = 0; index < result.genes.length && !has_error; index++) {
            const ct = result.genes[index].rv.toString().trim()
            if (ct !== "" && (!Utils.is_valid_number(ct) || (parseFloat(ct) <= 0 || parseFloat(ct) >= 100))) {
                has_error = true
                ToastsStore.error(`Enter valid Ct value for ${result.genes[index].ina}`)
            }
        }

        if (!has_error) {
            if (result.results === 'N/A') {
                ToastsStore.error('Results cannot be "N/A", please select the results')
            } else {
                setLoader({message: "Updating test results, please wait", show: true})
                Request.update_tests({
                    diagnosis_no: params.diagnosis_no, results: result.results, genes: JSON.stringify(result.genes)
                })
                    .then((response) => {
                        setLoader({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                params.setTests(
                                    params.tests.map((test) =>
                                        test.diagnosis_no === params.diagnosis_no ?
                                            {...test, results: result.results, genes: response.data.data.genes, result_status: 'results'} :
                                            {...test}
                                    ))
                                ToastsStore.success("Test results updated successfully")
                                close()
                            } else if (response.data.code === 2) {
                                setMessage({active: true, type: 'error', message: "Sample was not found or has already been verified"})
                            }
                        } else {
                            setMessage({active: true, message: "Could not update results, please retry", type: 'error'})
                        }
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                        setMessage({active: true, message: "Could not update results, please retry", type: 'error'})
                    })
            }
        }
    }

    const compare_objects = (object1: any, object2: any): boolean => {
        const keys1 = Object.keys(object1)
        const keys2 = Object.keys(object2)
        if (keys1.length !== keys2.length) {
            return false
        }
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false
            }
        }
        return true
    }

    const calculate_results = (value: string, gi: number, co: number) => {
        const machines = params.machines.filter((machine) => machine.machine_id === params.machine_id)
        if (machines.length === 1) {
            const _inter: Interpretation = {rs: 'Positive', genes: values}

            if (value === "") {
                _inter.genes[gi.toString()] = 'Negative'
            } else if (!Utils.is_valid_number(value) || parseFloat(value) <= 0 || parseFloat(value) >= 100) {
                _inter.genes[gi.toString()] = 'Invalid'
            } else {
                _inter.genes[gi.toString()] = parseFloat(value) <= co ? 'Positive' : 'Negative';
            }

            let results: 'Invalid' | 'Positive' | 'Negative' | "Inconclusive" = 'Invalid'
            for (let index = 0; index < machines[0].interpretations.length && results === 'Invalid'; index++) {
                if (compare_objects(machines[0].interpretations[index].genes, _inter.genes)) {
                    results = machines[0].interpretations[index].rs
                }
            }

            setValues(_inter.genes)
            return {rs: _inter.genes[gi.toString()], rp: results}
        } else {
            return null
        }
    }

    React.useEffect(() => {

    }, [result.genes])

    React.useEffect(() => {
        setResult({genes: params.genes, results: params.results})
    }, [params.diagnosis_no])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='mini' open={params.diagnosis_no !== ""} centered={false} onClose={close}>
                <div className='p-3'>
                    <div className="test_header mb-3 mt-0">Update Test Results</div>

                    <div className="label mb-1">Diagnosis Number</div>
                    <MyInput placeholder="Enter diagnosis number" name="diagnosis_no" value={params.diagnosis_no}/>

                    <div className="label mt-3 mb-1">C<sub>t</sub> Values Information</div>
                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '120px'}}>C<sub>t</sub> Gene</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '90px'}}>C<sub>t</sub> Value</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Results</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {

                                    result.genes.map((gene) =>
                                        <Table.Row key={gene.gi}>
                                            <Table.Cell style={{width: '120px'}}>{`${gene.co}- ${gene.ina}`}</Table.Cell>
                                            <Table.Cell style={{width: '90px'}}>
                                                <MyInput
                                                    placeholder="Ct value" name="" value={gene.rv.toString()}
                                                    change={(name, value) => {
                                                        const calculated = calculate_results(value.trim(), gene.gi, gene.co)
                                                        setResult({
                                                            ...result,
                                                            results: calculated != null ? calculated.rp : result.results,
                                                            genes: result.genes.map((_gene, _index) =>
                                                                _gene.gi === gene.gi ?
                                                                    {
                                                                        ..._gene, rv: value,
                                                                        rs: calculated !== null ? calculated.rs : _gene.rs
                                                                    } :
                                                                    {..._gene}
                                                            )
                                                        })
                                                    }}/>
                                            </Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{gene.rs}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="label mt-3 mb-1">Results Interpretation</div>
                    <MySelect placeholder="Select machine name" name="results" value={result.results} disabled={true}
                              change={(value) => setResult({...result, results: value as any})}
                              options={[
                                  {text: "Not Applicable", value: 'N/A'},
                                  {text: "Invalid", value: 'Invalid'},
                                  {text: "Negative", value: 'Negative'},
                                  {text: "Positive", value: 'Positive'},
                              ]}/>

                    <div className="row mx-0 mt-3">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative icon="trash" labelPosition="left" size="tiny" fluid content="Clear data" onClick={close}/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid content="Update Results"
                                    onClick={update_results}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
