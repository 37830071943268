import {Request} from "../../../../../utils/Request";
import React from "react";
import {ReportTest} from "../PCRTests";
import {Button, Checkbox, Modal, Table} from "semantic-ui-react";
import {ToastsStore} from "react-toasts";
import Utils, {Loading} from "../../../../../utils/Utils";

export default function ResultsInfo(params: { show: boolean, tests: ReportTest[], setTests: (tests: ReportTest[]) => void, close: () => void }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [ids, setIds] = React.useState(Array<number>())
    const [completed, setCompleted] = React.useState(Array<number>())

    const verify_results = () => {
        setLoader({message: "Verifying results, please wait", show: true})
        Request.verify_all_pcr_test({ids: JSON.stringify(ids)})
            .then((response) => {
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        params.setTests(params.tests.map((test) =>
                            ids.includes(test.diagnosis_id) ?
                                {...test, result_status: 'verified', verified_by: response.data.user_name, date_verified: response.data.time} :
                                {...test}
                        ))
                        ToastsStore.success("Successfully verified PCR results")
                    } else {
                        ToastsStore.error("Could not verify results, please retry")
                    }
                }
                setLoader({message: "", show: false})
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not verify results, please retry")
            })
    }

    React.useEffect(() => {
        setCompleted(
            params.tests
                .filter((test) => test.result_status === "results")
                .map((test) => (test.diagnosis_id))
        )
    }, [params.tests])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Modal size={'small'} open={params.show} onClose={params.close} centered={false}
                   closeOnDimmerClick={false} closeOnEscape={false} closeOnDocumentClick={false}>
                <div className="test_result results">
                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">
                                        <Checkbox
                                            checked={completed.length > 0 && completed.length === ids.length}
                                            onChange={(event, value) => {
                                                setIds((value.checked as boolean) ? completed.map((result) => (result)) : [])
                                            }}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">No</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Diagnosis No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '300px'}}>C<sub>t</sub> Values</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Results</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    params.tests
                                        .filter((test) => ['results', 'loaded'].includes(test.result_status))
                                        .map((sample, index) =>
                                            <Table.Row>
                                                <Table.Cell style={{width: '40px'}}>
                                                    <Checkbox
                                                        className="ml-2" checked={ids.includes(sample.diagnosis_id)}
                                                        disabled={sample.result_status !== 'results'}
                                                        onChange={(event, value) => {
                                                            if (value.checked as boolean) {
                                                                setIds([...ids, sample.diagnosis_id])
                                                            } else {
                                                                setIds(ids.filter((id) => id !== sample.diagnosis_id))
                                                            }
                                                        }}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{sample.diagnosis_no}</Table.Cell>
                                                <Table.Cell style={{width: '300px'}}>{sample.gene}</Table.Cell>
                                                <Table.Cell style={{width: '100px'}}>{sample.results}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="mt-2 row mx-0">
                        <div className="col-6 col-md-3 offset-md-3 pl-0 pr-1">
                            <Button negative icon="close" labelPosition="left" size="tiny" fluid
                                    content="Close Window" onClick={params.close}/>
                        </div>
                        <div className="col-6 col-md-3 pl-1 pr-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                    content="Verify Results" onClick={verify_results} disabled={ids.length === 0}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
