import PCRMachines, {Machine} from "./PCRMachines";
import {ReportTest} from "../../PCRTests";
import {Modal, Tab} from "semantic-ui-react";
import React from "react";
import ReceiveSamples from "./ReceiveSamples";
import LoadSamples from "./LoadSamples";
import UploadCSV from "./UploadCSV";

export default function Laboratory(params: {
    show: boolean, close: () => void,
    tests: ReportTest[], setTests: (tests: ReportTest[]) => void,
    machines: Machine[], setMachines: (machines: Machine[]) => void
}) {
    return (
        <>
            <Modal size='small' open={params.show} centered={false} closeOnDimmerClick={false}
                   closeOnDocumentClick={false} closeOnEscape={false} onClose={params.close}>
                <div className="laboratory_modal">
                    <Tab menu={{pointing: true}}
                         panes={[
                             {
                                 menuItem: 'PCR Machines',
                                 render: () =>
                                     <Tab.Pane attached={false}>
                                         <PCRMachines machines={params.machines} setMachines={params.setMachines} close={params.close}/>
                                     </Tab.Pane>,
                             },
                             {
                                 menuItem: 'Receive Samples',
                                 render: () =>
                                     <Tab.Pane attached={false}>
                                         <ReceiveSamples tests={params.tests} setTests={params.setTests} close={params.close}/>
                                     </Tab.Pane>,
                             },
                             {
                                 menuItem: 'Load Samples',
                                 render: () =>
                                     <Tab.Pane attached={false}>
                                         <LoadSamples tests={params.tests} setTests={params.setTests}
                                                      close={params.close} machines={params.machines}/>
                                     </Tab.Pane>,
                             },
                             {
                                 menuItem: 'Upload CSV',
                                 render: () =>
                                     <Tab.Pane attached={false}>
                                         <UploadCSV close={params.close} machines={params.machines}/>
                                     </Tab.Pane>,
                             },
                         ]}
                    />
                </div>
            </Modal>
        </>
    )
}
