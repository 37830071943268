import {Request} from "../../../../../utils/Request";
import {ToastsStore} from "react-toasts";
import Utils, {Loading, MessageProps, my_initial_confirm, MyConfirmAction, MyMessage} from "../../../../../utils/Utils";
import React from "react";
import {ReportTest} from "../PCRTests";
import {Button, Checkbox, Confirm, Modal, Table} from "semantic-ui-react";

interface Result {
    diagnosis_id: number
    diagnosis_no: string
    patient_id: string
    created: string
    received: string
    facility_type: string
    facility_name: string
    name: string
    gender: string
    age: number
    nationality: string
    contact: string
    district: string
    swabbed: string
    worksheet: string
    type: string
    results: string
    date_verified: string
    passport_no: string
    email: string
    purpose: string
    receipt: string
    positive: string
    vaccinated: string
    vaccine: string
    verified_by: string
    updated_by: string
}

interface Response {
    code: number
    sample: string
    res: string
    status: 'success' | 'invalid' | 'conflict' | 'unauthorised' | 'unknown'
}

export default function CSVResults(
    params: {
        show: boolean, tests: ReportTest[], setTests: (tests: ReportTest[]) => void, close: (submitted: boolean) => void,
        search: { max_date: string, min_date: string, status: string, upload_status: number, center_id: number }
    }
) {
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [results, setResults] = React.useState(Array<Result>())
    const [ids, setIDS] = React.useState(Array<number>())
    const [responses, setResponses] = React.useState(Array<Response>())
    const [showResponses, setShowResponses] = React.useState(false)

    const load_results = () => {
        setLoader({message: "Loading pending verified results, please wait", show: true})
        Request.load_csv_results({
            max_date: params.search.max_date, min_date: params.search.min_date, status: params.search.status,
            upload_status: params.search.upload_status, center_id: params.search.center_id
        })
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setResults(response.data.data)
                } else {
                    ToastsStore.error("Could load verified results, please retry")
                }
                setLoader({message: "", show: false})
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not load verified results, please retry")
            })
    }

    const download_csv_file = () => {
        setLoader({message: "Loading csv file, please wait", show: true})
        Request.download_csv_file({
            ids: JSON.stringify(ids), max_date: params.search.max_date, min_date: params.search.min_date, status: params.search.status,
            upload_status: params.search.upload_status, center_id: params.search.center_id
        })
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    const anchor = document.createElement('a')
                    anchor.href = `${Request.BASE_URL}${response.data.data.path}`
                    anchor.download = response.data.data.name
                    anchor.click()
                    upload_csv_results()
                } else {
                    ToastsStore.error("Could not download csv file, please retry")
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not download csv file, please retry")
            })
    }

    const upload_csv_results = () => {
        setConfirm({
            ...confirm,
            open: true, content: 'Please download the generated CSV file and press "Proceed" button to mark the results as submitted',
            confirmButton: "Proceed", cancelButton: "Not Received",
            onCancel: () => setConfirm({...confirm, open: false}),
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                setLoader({message: "Marking results as uploaded, please wait", show: true})
                Request.upload_csv_results({ids: JSON.stringify(ids)})
                    .then((response) => {
                        setLoader({message: "", show: false})
                        if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                            ToastsStore.success("Successfully marked results as received")
                            params.close(true)
                        } else {
                            ToastsStore.error("Could not mark results as received, please retry")
                            upload_csv_results()
                        }
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                        ToastsStore.error("Could not mark results as received, please retry")
                        upload_csv_results()
                    })
            }
        })
    }

    const synchronise_csv_results = () => {
        setLoader({message: "Dispatching results to RDS, please wait", show: true})
        Request.synchronise_csv_file({ids: JSON.stringify(ids)})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        ToastsStore.success("Successfully synchronised results to RDS")
                        setResponses(response.data.responses)
                        params.close(true)
                    } else {
                        ToastsStore.error("Could not synchronise results to RDS, please retry")
                    }
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                ToastsStore.error("Could not synchronise results to RDS, please retry")
            })
    }

    const load_results_info = (diagnosis_id: number) => {

    }

    const get_response_reason = (response: Response): string => {
        switch (response.status) {
            case "conflict":
                return "Already Uploaded"
            case "invalid":
                return "Invalid Data"
            case "unauthorised":
                return "Not Authorised"
            case "unknown":
                return "Unknown Reason"
            default:
                return "Successful"
        }
    }

    React.useEffect(() => {
        setShowResponses(responses.filter((response) => (response.status !== "success")).length > 0)
    }, [responses])

    React.useEffect(() => {
        if (params.show) {
            setResults([])
            setIDS([])
            setResponses([])
            load_results()
        }
    }, [params.show])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content} centered={false}/>

            <Modal size='fullscreen' open={params.show} centered={false} closeOnDimmerClick={!loader.show}
                   closeOnDocumentClick={!loader.show} closeOnEscape={!loader.show} onClose={() => params.close(false)}>
                <div className='csv_modal'>
                    <div className="content_bar">
                        <div className="search_bar">
                        </div>
                        <div className="content_buttons">
                            <Button primary size='mini' icon='search' labelPosition="left"
                                    content="Search Results" onClick={load_results}/>

                            <Button primary size='mini' icon='download' labelPosition="left"
                                    disabled={ids.length === 0} content="Download CSV" onClick={download_csv_file}/>

                            <Button primary size='mini' icon='sync' labelPosition="left" content="Sync Results"
                                    disabled={ids.length === 0} onClick={synchronise_csv_results}/>
                        </div>
                    </div>

                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '80px'}} textAlign="center">
                                        <Checkbox
                                            checked={results.length > 0 && results.length === ids.length}
                                            onChange={(event, value) => {
                                                if (value.checked as boolean) {
                                                    setIDS(results.map((result) => (result.diagnosis_id)))
                                                } else {
                                                    setIDS([])
                                                }
                                            }}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>SAMPLE ID</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>COLLECTION DATE</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>RECEIPT DATE</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>DATE OF TESTING</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>PATIENT NAME</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>BEING TESTED</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>TEST TYPE</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>RESULT</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>PASSPORT NUMBER</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>EMAIL ADDRESS</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}}>SEX</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}} textAlign='center'>AGE</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>NATIONALITY</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>CONTACT</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>PATIENT DISTRICT</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '90px'}}>POSITIVE</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>VACCINATED</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '90px'}}>VACCINE</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>PATIENT ID</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>FACILITY TYPE</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>FACILITY NAME</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>SWAB DISTRICT</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>WORKSHEET</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>RECEIPT N0.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>APPROVED BY</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>SIGNATURE</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    results.map((result, index) =>
                                        <Table.Row key={result.diagnosis_no}>
                                            <Table.Cell style={{width: '80px'}} textAlign="center">
                                                <Button primary size='mini' icon='edit'
                                                        onClick={() => load_results_info(result.diagnosis_id)}/>

                                                <Checkbox
                                                    className="ml-2" checked={ids.includes(result.diagnosis_id)}
                                                    onChange={(event, value) => {
                                                        if (value.checked as boolean) {
                                                            setIDS([...ids, result.diagnosis_id])
                                                        } else {
                                                            setIDS(ids.filter((id) => id !== result.diagnosis_id))
                                                        }
                                                    }}/>
                                            </Table.Cell>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{result.diagnosis_no}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{result.created}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{result.received}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>DATE OF TESTING</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{result.name}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{result.purpose}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{result.type}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{result.results}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{result.passport_no}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{result.email.toLowerCase()}</Table.Cell>
                                            <Table.Cell style={{width: '70px'}}>{result.gender}</Table.Cell>
                                            <Table.Cell style={{width: '70px'}} textAlign='center'>{result.age}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{result.nationality}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{result.contact}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{result.district}</Table.Cell>
                                            <Table.Cell style={{width: '90px'}} textAlign='center'>{result.positive}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}} textAlign='center'>{result.vaccinated}</Table.Cell>
                                            <Table.Cell style={{width: '90px'}}>{result.vaccine}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{result.patient_id}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{result.facility_type}</Table.Cell>
                                            <Table.Cell style={{width: '250px'}}>{result.facility_name}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{result.swabbed}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{result.worksheet}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{result.receipt}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{result.verified_by}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{result.verified_by}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Modal>

            <Modal size='mini' open={showResponses} centered={false} onClose={() => setShowResponses(false)}>
                <div className='response_modal'>
                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Diagnosis No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Failure Reason</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    responses
                                        .filter((response) => (response.status !== "success"))
                                        .map((response, index) =>
                                            <Table.Row key={response.sample}>
                                                <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{response.sample}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{get_response_reason(response)}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Modal>
        </>
    )
}
