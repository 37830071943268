import React from "react";
import {Button, Checkbox, Confirm, Table} from "semantic-ui-react";
import Utils, {Loading, MessageProps, my_initial_confirm, MyConfirmAction, MyDateInput, MyMessage, MySelect, SelectData, ShowPDF} from "../../../utils/Utils";
import {Request} from "../../../utils/Request";
import {SystemUser} from "../../AppBar";
import ModifyPayment from "./ModifyPayment";
import PricingData from "../management/PricingData";
import JSPrinter from "../../../utils/JSPrinter";
import ReceiveSamples from "./ReceiveSamples";

interface Diagnosis {
    client_id: number
    client_name: string
    date_created: string
    diagnosis_id: number
    diagnosis_no: string
    discount: number
    discount_reason: string
    group_name: string
    payment_info_id: number
    payments: PaymentData[]
    test_amount: number
    test_name: string
    username: string
}

interface PaymentData {
    payment_data_id: number
    ref_no: string,
    account_name: string,
    payment_type: 'cash' | 'mobile' | 'debit' | 'credit' | 'company' | 'pesapal' | 'dtb',
    amount_paid: number,
    time_modified: string,
    modified_by: string
}

export default function PaymentReport(props: { systemUser: SystemUser, centers: Array<SelectData>, user_token: string }) {
    const [users, setUsers] = React.useState<Array<SelectData>>([])
    const [dayUsers, setDayUsers] = React.useState<Array<SelectData>>([])
    const [fromStart, setFromStart] = React.useState(false)
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)

    const [showReceipt, setShowReceipt] = React.useState({show: false, url: ""})
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [diagnosis, setDiagnosis] = React.useState<Array<Diagnosis>>([])
    const [pdf, setPdf] = React.useState(false)

    const [url, setUrl] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [search, setSearch] = React.useState({
        min_date: Utils.today(), max_date: Utils.today(), cashier_name: props.systemUser.userID, center_id: props.systemUser.center.center_id
    })

    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const load_daily_users = () => {
        Request.get_day_users({date: search.min_date, user_id: props.systemUser.user_id, center_id: search.center_id})
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code == 1) {
                    setDayUsers([
                        {text: 'All Users', value: 0},
                        ...(response.data.data as Array<{ username: string, user_id: number }>)
                            .map((user) => ({text: user.username, value: user.user_id}))
                    ])
                }
            })
    }

    const search_payments = () => {
        setLoading(true)
        Request.get_payments({
            min_date: search.min_date, max_date: search.max_date, cashier_name: search.cashier_name, center_id: search.center_id
        })
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setUsers(response.data.data.users)
                    setDiagnosis(response.data.data.diagnosis)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const pdf_payment_report = () => {
        setLoading(true)
        Request.get_payments_pdf({
            min_date: search.min_date, max_date: search.max_date, cashier_name: search.cashier_name, center_id: search.center_id
        })
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setUrl(Request.BASE_URL + response.data.data.url)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const print_xml = () => {
        const rows: Array<any> = [];
        rows.push([
            "Payment ID", "Diagnosis No.", "Test Type", "Date Created", "Cashier name", "Client Name", "Client group",
            "Payment Method", "Payment Type", "Account Info", "Ref No", "Amount Paid", "Discount", "Discount Reason"
        ])

        /*payments.forEach((payment) => {
            rows.push([
                payment.payment_id, payment.diagnosis_no, payment.test_type, payment.date_created, payment.user_name, payment.client_name,
                payment.group_name, payment.payment_type, payment.payment_method, payment.account_info, payment.ref_no, payment.amount_paid,
                payment.discount, payment.discount_reason
            ])
        })*/

        Utils.generate_csv(rows)
    }

    const load_receipt = (diagnosis_id: number, payment_info_id: number) => {
        const generate_receipts = (payment_id: number) => {
            setLoader({message: "Printing receipt, please wait", show: true})
            Request.print_receipt({diagnosis_id: diagnosis_id, payment_info_id: payment_id})
                .then((response) => {
                    setLoader({message: "Printing receipt, please wait", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        if (Request.COMPANY === "PHARMA") {
                            JSPrinter.print_receipt(response.data.data)
                        } else {
                            setShowReceipt({show: true, url: Request.BASE_URL + response.data.data.url})
                        }
                    }
                })
                .catch(() => {
                    setLoader({message: "Printing receipt, please wait", show: false})
                })
        }

        if (diagnosis.filter((test) => test.payment_info_id !== 0 && test.payment_info_id === payment_info_id).length < 2) {
            generate_receipts(0)
        } else {
            setConfirm({
                ...confirm, open: true, content: "Do you wish to generate all batch receipts at once?",
                confirmButton: "Print All", cancelButton: "Print Single",
                onCancel: () => {
                    setConfirm({...confirm, open: false});
                    generate_receipts(0)
                },
                onConfirm: () => {
                    setConfirm({...confirm, open: false})
                    generate_receipts(payment_info_id)
                }
            })
        }

    }
    const [edit, setEdit] = React.useState({payment_info_id: 0, show: false, client_id: 0, diagnosis_id: 0})
    const [showReceive, setShowReceive] = React.useState(false)

    React.useEffect(() => {
        load_daily_users()
    }, [search.min_date, search.max_date])

    React.useEffect(() => {
        setSearch({...search, min_date: fromStart ? '2020-06-06' : Utils.today()})
    }, [fromStart])

    React.useEffect(() => {
        search_payments()
    }, [])

    const [showPayment, setShowPayment] = React.useState(false)

    return (
        <>
            <PricingData close={() => setShowPayment(false)} show={showPayment}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content} centered={false}/>

            <ModifyPayment
                payment_info_id={edit.payment_info_id} show={edit.show} user={props.systemUser} diagnosis_id={edit.diagnosis_id}
                client_id={edit.client_id} user_token={props.user_token}
                hide={(modified?: boolean) => {
                    if (modified) {
                        search_payments()
                    }
                    setEdit({payment_info_id: 0, show: false, client_id: 0, diagnosis_id: 0});
                }}/>

            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <ReceiveSamples show={showReceive} close={() => setShowReceive(false)} centers={props.centers}/>

            <ShowPDF show={showReceipt.show} close={() => setShowReceipt({...showReceipt, show: false})} url={showReceipt.url}/>

            <>
                <div className="content_bar two_buttons">
                    <div className="search_bar">
                        <div className='dropdown'>
                            <Button icon='ordered list' primary size='tiny'/>

                            <div className='drop_list'>
                                <div className='hide_large'>
                                    <label>Cashier Name</label>
                                    <MySelect
                                        change={(value) => handle_search("cashier_name", value as string)}
                                        name="cashier_name" value={search.cashier_name} placeholder="Select cashier"
                                        options={search.max_date === search.min_date ? dayUsers : users}
                                        disabled={props.systemUser.userID > 0}/>

                                    <Checkbox label='From Beginning' checked={fromStart}
                                              onChange={(event, data) => {
                                                  setFromStart(data.checked as boolean)
                                              }}/>

                                    <label>Minimum Date</label>
                                    <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                                 change={handle_search} maxDate={Utils.today()}/>

                                    <label>Maximum Date</label>
                                    <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                                 change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                                </div>

                                <label>Center Name</label>
                                <MySelect
                                    disabled={
                                        Request.COMPANY === 'MEDSAFE' ||
                                        !Utils.has_center_role({role: "manage_centers", roles: props.systemUser.center.center_roles})
                                    }
                                    name={'center_id'} value={search.center_id} placeholder='Select a center'
                                    change={(value) => handle_search('center_id', value as number)}
                                    options={[{text: 'Show all centers', value: 0}, ...props.centers]}/>
                            </div>
                        </div>
                        <div className='hide_small'>
                            <MySelect
                                change={(value) => handle_search("cashier_name", value as string)}
                                name="cashier_name" value={search.cashier_name} placeholder="Select cashier"
                                options={search.max_date === search.min_date ? dayUsers : users}
                                disabled={props.systemUser.userID > 0}/>
                        </div>
                        <div className='search_check hide_small'>
                            <Checkbox label='From Beginning' checked={fromStart}
                                      onChange={(event, data) => {
                                          setFromStart(data.checked as boolean)
                                      }}/>
                        </div>
                        <div className='hide_small'>
                            <MyDateInput value={search.min_date} name="min_date" placeholder="Minimum payment date"
                                         change={handle_search} maxDate={Utils.today()}/>
                        </div>
                        <div className='hide_small'>
                            <MyDateInput value={search.max_date} name="max_date" placeholder="Maximum payment date"
                                         change={handle_search} maxDate={Utils.today()} minDate={search.min_date}/>
                        </div>
                    </div>

                    <div className="content_buttons d-none d-md-inline-block">
                        <div className="payment_button">
                            <Button
                                positive size='mini' loading={loading} disabled={loading} content='Search'
                                onClick={search_payments}/>
                        </div>

                        <div className="payment_button">
                            <Button
                                negative size='mini' content='XML' onClick={print_xml}
                                disabled={loading || diagnosis.length === 0 || !props.systemUser.roles.includes("payment_reports")}/>
                        </div>

                        <div className="payment_button">
                            <Button
                                negative size='mini' disabled={loading} content='PDF'
                                onClick={() => {
                                    setPdf(true)
                                    pdf_payment_report()
                                }}/>
                        </div>

                        <div className="payment_button">
                            <Button negative size='mini' disabled={loading} content='Table' onClick={() => setPdf(false)}/>
                        </div>
                    </div>

                    <div className="content_buttons d-md-none">
                        <Button positive size='mini' loading={loading} disabled={loading} icon='search' onClick={search_payments}/>

                        <Button
                            negative size='mini' icon='file excel' onClick={print_xml}
                            disabled={loading || diagnosis.length === 0 || !props.systemUser.roles.includes("payment_reports")}/>

                        <Button
                            negative size='mini' disabled={loading} icon='file pdf'
                            onClick={() => {
                                setPdf(true)
                                pdf_payment_report()
                            }}/>
                        <Button negative size='mini' disabled={loading} icon='table' onClick={() => setPdf(false)}/>
                    </div>
                </div>

                <div className="table_container has_footer">
                    {
                        !pdf &&
                        <Table celled striped singleLine={false} compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>

                                    <Table.HeaderCell style={{width: '120px'}} textAlign="center"/>
                                    <Table.HeaderCell style={{width: '140px'}}>Time Tested</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Cashier Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Test No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Test Type</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>Group Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Test Amount</Table.HeaderCell>

                                    <Table.HeaderCell style={{width: '110px'}}>Payment Type</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '160px'}}>Reference No</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '160px'}}>Account Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Amount Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Modified By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Time Modified</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    diagnosis.map((diagnosis, index) =>
                                        <Table.Row key={diagnosis.diagnosis_id}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center" rowSpan={diagnosis.payments.length}>
                                                {Utils.row_number(index)}
                                            </Table.Cell>

                                            <Table.Cell style={{width: '120px'}} textAlign="center" rowSpan={diagnosis.payments.length}>
                                                <Button primary size='mini' icon='barcode' compact
                                                        onClick={() => JSPrinter.print_code(diagnosis.diagnosis_no)}/>
                                                <Button primary size='mini' icon='file pdf' compact
                                                        onClick={() => load_receipt(diagnosis.diagnosis_id, diagnosis.payment_info_id)}/>
                                                {
                                                    props.systemUser.roles.includes("adjust_payments") &&
                                                    <Button
                                                        primary size='mini' icon='edit' compact
                                                        onClick={() => {
                                                            setEdit({
                                                                payment_info_id: diagnosis.payment_info_id,
                                                                show: true, client_id: diagnosis.client_id,
                                                                diagnosis_id: diagnosis.diagnosis_id
                                                            });
                                                        }}>
                                                    </Button>
                                                }
                                            </Table.Cell>
                                            <Table.Cell style={{width: '140px'}} rowSpan={diagnosis.payments.length}>
                                                {diagnosis.date_created}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '150px'}} rowSpan={diagnosis.payments.length}>
                                                {diagnosis.username}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '250px'}} rowSpan={diagnosis.payments.length}>
                                                {diagnosis.client_name}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '100px'}} rowSpan={diagnosis.payments.length}>
                                                {diagnosis.diagnosis_no}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '150px'}} rowSpan={diagnosis.payments.length}>
                                                {diagnosis.test_name}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '250px'}} rowSpan={diagnosis.payments.length}>
                                                {diagnosis.group_name}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '120px'}} rowSpan={diagnosis.payments.length}>
                                                {Utils.comma_number(diagnosis.test_amount)}
                                            </Table.Cell>

                                            {
                                                diagnosis.payments.length === 0 &&
                                                <Table.Cell style={{width: '830px'}} colSpan={6} textAlign="center">
                                                    <b>No payment attached (Credit transaction)</b>
                                                </Table.Cell>
                                            }

                                            {
                                                diagnosis.payments
                                                    .map((_payment) =>
                                                        <>
                                                            <Table.Cell style={{width: '110px'}}>
                                                                {Utils.get_method(_payment.payment_type)}
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '160px'}}>{_payment.ref_no}</Table.Cell>
                                                            <Table.Cell style={{width: '160px'}}>{_payment.account_name}</Table.Cell>
                                                            <Table.Cell style={{width: '120px'}}>
                                                                {Utils.comma_number(_payment.amount_paid)}
                                                            </Table.Cell>
                                                            <Table.Cell style={{width: '140px'}}>{_payment.modified_by}</Table.Cell>
                                                            <Table.Cell style={{width: '140px'}}>{_payment.time_modified}</Table.Cell>
                                                        </>
                                                    )
                                            }
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    }
                    {
                        url !== "" && pdf && <iframe src={url} style={{width: '100%', height: '100%'}} frameBorder="0"/>
                    }
                </div>

                <div className="row m-0 align-items-end">
                    {
                        props.systemUser.roles.includes("make_payment") &&
                        <div className="col-2 px-1 py-0">
                            <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                    content="Receive Samples" onClick={() => setShowReceive(true)}/>
                        </div>
                    }

                    {
                        props.systemUser.roles.includes("manage_prices") &&
                        <div className="col-2 px-1 py-0">
                            <Button primary size='mini' icon='money' labelPosition="left" content="Sample Pricing" fluid
                                    onClick={() => setShowPayment(true)}/>
                        </div>
                    }
                </div>
            </>
        </>
    )
}
