import {Loading, MyInput} from "../../../../../../utils/Utils";
import {Button, Table} from "semantic-ui-react";
import React from "react";
import {Request} from "../../../../../../utils/Request";
import {ReportTest} from "../../PCRTests";
import {ToastsStore} from "react-toasts";

export default function ReceiveSamples(params: { tests: ReportTest[], setTests: (tests: ReportTest[]) => void, close: () => void }) {
    const [loader, setLoader] = React.useState({show: false, message: ""})

    const [samples, setSamples] = React.useState(Array<String>())
    const [sample, setSample] = React.useState("")

    const add_sample = () => {
        if (samples.length >= 96) {
            ToastsStore.error("Maximum of 96 samples is allowed")
        } else if (sample.trim().length < 5) {
            ToastsStore.error("Enter a valid diagnosis number")
        } else if (samples.filter((_diagnosis_no) => sample.trim() === _diagnosis_no).length > 0) {
            ToastsStore.error("Diagnosis number is already received in the list")
        } else {
            setSamples([...samples, sample.trim()])
            setSample("")
        }
    }

    const close = () => {
        setSamples([])
        params.close()
    }

    const receive_results = () => {
        if (samples.length === 0) {
            ToastsStore.error("No samples have been added")
        } else {
            setLoader({message: "Receiving PCR samples", show: true})
            Request.receive_samples({samples: JSON.stringify(samples)})
                .then((response) => {
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        if (response.data.data.unavailable.length === 0) {
                            ToastsStore.success("Test results received successfully")
                        } else {
                            ToastsStore.info("Some test samples could not be found or have already been received")
                        }
                        setSamples(response.data.data.unavailable)

                        const received: string[] = response.data.data.available as string[]
                        params.setTests(
                            params.tests.map((test) => {
                                return received.includes(test.diagnosis_no) ? {...test, result_status: 'received'} : test;
                            })
                        )
                    } else {
                        ToastsStore.error("Could not receive results, please retry")
                    }
                    setLoader({message: "", show: false})
                })
                .catch(() => {
                    setLoader({message: "", show: false})
                    ToastsStore.error("Could not receive results, please retry")
                })
        }
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <div className="modal_content">
                <div className='lab_form' style={{height: 'calc(100% - 50px)'}}>
                    <div className="test_header mt-0 mb-2">Receive PCR Samples</div>

                    <div style={{background: 'white', padding: '10px'}}>
                        <div className="row m-0">
                            <div className="col-8 col-md-9 pl-1 pr-1">
                                <div className="label mb-1">Scan or enter sample barcode no.</div>
                                <MyInput placeholder="Enter sample diagnosis no" name="sample" value={sample} enter={add_sample}
                                         change={(name, value) => setSample(value)}/>
                            </div>
                            <div className="col-4 col-md-3  pl-1 pr-0">
                                <div className="label mb-1" style={{height: '12.59px'}}/>
                                <Button primary fluid size='mini' icon='add' onClick={add_sample} content="Add Sample"/>
                            </div>
                        </div>
                    </div>

                    <div className="received_container mt-2">
                        <div className="table_container table_100">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '50px'}}/>
                                        <Table.HeaderCell style={{width: '300px'}}>Diagnosis No.</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        samples.map((sample, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                                <Table.Cell style={{width: '50px'}}>
                                                    <Button
                                                        primary size='mini' icon='trash' compact
                                                        onClick={() => setSamples(samples.filter((_sample) => _sample !== sample))}>
                                                    </Button>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '300px'}}>{sample}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

                <div className="row mx-0 p-0">
                    <div className="col-6 pl-0 pr-1">
                        <Button negative icon="trash" labelPosition="left" size="tiny" fluid content="Close Window" onClick={close}/>
                    </div>
                    <div className="col-6 pl-1 pr-0">
                        <Button positive icon="save" labelPosition="left" size="tiny" fluid
                                content="Receive Samples" onClick={receive_results}/>
                    </div>
                </div>
            </div>
        </>
    )
}
